import React from "react";
import styled from "styled-components";
import Navbar from "./Navbar";

const StyledHeader = styled.header`
  padding: 40px 40px 0 40px;
  text-align: center;

  @media ${({ theme }) => theme.mediaQueries.belowLarge} {
    padding: 16px 16px 0 16px;
  }

  @media ${({ theme }) => theme.mediaQueries.belowSmall} {
    padding: 8px 8px 0 8px;
  }
`;

const Header = () => (
  <StyledHeader>
    <Navbar />
  </StyledHeader>
)

export default Header;