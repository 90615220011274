import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const ExternalLink = styled.a`
  font-size: 16px;
  color: #1A1135;
  text-decoration: none;

  &:hover {
    font-size: 16px;
    color: #311387;
    text-decoration: none;
  }
`;

const InternalLink = styled(Link)`
  font-size: 16px;
  color: #1A1135;
  text-decoration: none;

  &:hover {
    font-size: 16px;
    color: #311387;
    text-decoration: none;
  }
`;

const BaseLink = ({ children, to, internal }) => {
  if (internal) {
    return (<InternalLink to={to}>{children}</InternalLink>)
  }

  return (<ExternalLink href={to}>{children}</ExternalLink>)
}

export default BaseLink;