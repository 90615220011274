import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

const StyledNavbar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
`;

const StyledRequest = styled.a`
  background-color: #FFF;
  color: #111;
  padding: 8px 15px;
  border-radius: 32px;
  border: 1px solid #111;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;

  &:hover {
    background-color: #111;
    color: #FFF;
  }
`;

const Navbar = () => {
  return (
    <StyledNavbar>
      <Link to="/">
        <StaticImage src="../images/logo.png" alt="logo" height={40} />
      </Link>
      <StyledRequest href="https://home.wundersquad.com/signup">Request</StyledRequest>
    </StyledNavbar>
  )
}

export default Navbar;