import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import BaseLink from "./Link";

const StyledFooter = styled.footer`
  margin-top: 96px;
  text-align: center;

  .logo {
    max-height: 40px;
  }
`;

const StyledLink = styled(BaseLink)`
  font-size: 12px;
  color: #454545;
`;

const Footer = () => (
  <StyledFooter>
    <p><StyledLink to="mailto:info@wundersquad.com">Contact</StyledLink></p>
    <p><StyledLink to="/about" internal>About</StyledLink></p>
    <StaticImage src="../images/logo_footer.png" alt="logo" height={40} />
  </StyledFooter>
)

export default Footer;