import React from "react";
import styled from "styled-components";

const StyledContent = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;

  @media ${({ theme }) => theme.mediaQueries.belowLarge} {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media ${({ theme }) => theme.mediaQueries.belowSmall} {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const Content = ({ children }) => (
  <StyledContent>
    {children}
  </StyledContent>
)

export default Content;