import { StaticImage } from "gatsby-plugin-image";
import * as React from "react"
import styled from "styled-components"
import Content from "../components/Content";
import Footer from "../components/Footer"
import Header from "../components/Header"

const StyledTitle = styled.h1`
  font-size: 88px;
  color: #111;
  margin-bottom: 0px;
  text-align: center;

  @media ${({ theme }) => theme.mediaQueries.belowLarge} {
    font-size: 72px;
  }

  @media ${({ theme }) => theme.mediaQueries.belowSmall} {
    font-size: 48px;
  }
`;

const StyledSubTitle = styled.h2`
  font-size: 32px;
  color: #111;
  padding-bottom: 24px;

  @media ${({ theme }) => theme.mediaQueries.belowLarge} {
    font-size: 24px;
  }

  @media ${({ theme }) => theme.mediaQueries.belowSmall} {
    font-size: 20px;
  }
`;

const StyledCTA = styled.a`
  background-color: #111111;
  color: white;
  padding: 25px 25px;
  border-radius: 4px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  z-index: 1;

  &:hover {
    background-color: #292929;
    color: white;
  }
`;

const StyledHeroWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;

  @media ${({ theme }) => theme.mediaQueries.belowLarge} {
    margin-top: 32px;
  }

  @media ${({ theme }) => theme.mediaQueries.belowSmall} {
    margin-top: 24px;
  }
`;

const StyledIntro = styled.div`
  font-size: 36px;
  text-align: justify;
  font-weight: 800;
  max-width: 68%;
  margin: auto;
  background: -webkit-linear-gradient(45deg, #111, #070053);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 24px;

  @media ${({ theme }) => theme.mediaQueries.belowLarge} {
    font-size: 24px;
    max-width: 90%;
  }

  @media ${({ theme }) => theme.mediaQueries.belowSmall} {
    font-size: 16px;
    max-width: 85%;
  }
`;

const IndexPage = () => {
  const heroStyle = {
    maxWidth: '75%',
    filter: 'drop-shadow(0px 4px 50px #EAEAEA)',
    borderRadius: '16px'
  }

  return (
    <>
      <title>Wunder squad</title>
      <Header />
      <Content>
        <StyledTitle>Where your company lives.</StyledTitle>
        <StyledSubTitle>Built for modern teams.</StyledSubTitle>
        <StyledCTA href="https://home.wundersquad.com/signup">Request access to private beta</StyledCTA>
        <StyledHeroWrapper>
          <StaticImage src="../images/hero.png" alt="hero" style={heroStyle} />
        </StyledHeroWrapper>
        <StyledIntro>
          Companies come in different shapes and sizes, this is why we built Wunder squad in a way you can use only the
          features you need.
        </StyledIntro>
      </Content>
      <Footer />
    </>
  )
}

export default IndexPage
